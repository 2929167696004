<template>
  <div class="default-inputfield">
    <p v-if="title">{{ title }}</p>
    <input
      v-if="!invalid"
      :class="{ center: center, invalid: invalid }"
      type="text"
      :placeholder="placeholder"
      @input="updateValue($event.target.value)"
      @keydown.enter="addAdmin()"
      :maxlength="maxlength"
      :value="value"
      :disabled="disabled"
    />
    <input
      v-else
      :class="{ center: center, invalid: invalid }"
      type="text"
      placeholder="Email not found. Try again."
      @input="updateValue($event.target.value)"
      @keydown.enter="addAdmin()"
      :maxlength="maxlength"
      @click="$emit('clearError')"
    />
    <svg
      v-if="invalid"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="26"
      viewBox="0 0 30 26"
      class="error-icon"
    >
      <path
        class="a"
        d="M13.268,3a2,2,0,0,1,3.465,0L28.27,23a2,2,0,0,1-1.732,3H3.463A2,2,0,0,1,1.73,23Z"
      />
      <g transform="translate(-49 -328)">
        <path class="b" d="M-.5,0h3L2,9H0Z" transform="translate(63 337)" />
        <circle
          class="b"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(62.5 348)"
        />
      </g>
    </svg>
    <svg
      @click="addAdmin()"
      v-if="!disabled && !removeable && !invalid"
      :class="{ active: showAddButton }"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      class="add-icon"
    >
      <circle class="a" cx="12" cy="12" r="12" />
      <path
        class="b"
        d="M21388.973,3034.836h12"
        transform="translate(-21383.002 -3023)"
      />
      <path
        class="b"
        d="M0,0H12"
        transform="translate(11.97 17.863) rotate(-90)"
      />
    </svg>
    <svg
      @click="removeAdmin()"
      v-if="!disabled && removeable"
      :class="{ active: removeable }"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      class="remove-button"
    >
      <g transform="translate(-320 -377)">
        <g transform="translate(320 377)">
          <path class="a" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" />
        </g>
        <line class="b" x2="10" transform="translate(327 389)" />
      </g>
    </svg>
  </div>
</template>

<script>
import validator from "validator";

export default {
  name: "DefaultAddDeleteTextInput",
  props: [
    "title",
    "placeholder",
    "value",
    "maxlength",
    "disabled",
    "center",
    "invalid",
    "removeable",
  ],
  data() {
    return {};
  },
  computed: {
    showAddButton() {
      if (this.validEmail(this.value)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    removeAdmin() {
      this.$emit("removeAdmin");
    },
    addAdmin() {
      if (this.showAddButton) {
        this.$emit("addAdmin");
      }
    },
    validEmail(email) {
      if (validator.isEmail(email)) return true;
      return false;
    },
    updateValue: function (value) {
      this.$emit("input", value);
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.error-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all ease 0.3s;
  .a {
    fill: #ff0064;
  }
  .b {
    fill: #fff;
  }
}
.remove-button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all ease 0.3s;
  .a {
    fill: #ff0064;
  }
  .b {
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
  }
}
.add-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all ease 0.3s;
  opacity: 0;

  .a {
    fill: $sleet;
  }
  .b {
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
  }
  &:hover {
    .a {
      fill: $blizzard;
      transition: all ease 0.3s;
    }
  }
  &.active {
    opacity: 1;
    cursor: pointer;
    &:hover {
      .a {
        fill: $oceanshade;
        transition: all ease 0.3s;
      }
    }
    .a {
      fill: $ocean;
      transition: all ease 0.3s;
    }
    .b {
      fill: none;
      stroke: #fff;
      stroke-width: 2px;
    }
  }
}
.default-inputfield {
  width: 100%;
  position: relative;
}
p {
  font-size: 16px;
  margin-bottom: 10px;
}

input {
  height: 48px;
  border: 1px solid $blizzard;
  outline: none;
  border-radius: 6px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  transition: all ease 0.3s;
  @include Gilroy-Bold;
  background-color: transparent;

  &.invalid {
    border: 2px solid $dusk;

    &::placeholder {
      padding-left: 45px;
      color: $dusk;
    }
  }

  &:disabled {
    color: $strom;
    background-color: $mist;

    &::placeholder {
      color: $blizzard;
      opacity: 1;
      transition: all ease 0.3s;
    }
  }

  &::placeholder {
    color: $midnight;
    @include Gilroy-Light;
    opacity: 1;
    transition: all ease 0.3s;
  }

  &:hover,
  &:focus {
    border: 1px solid $midnight;
    transition: all ease 0.3s;
    &::placeholder {
      color: $sleet;
      opacity: 0;
      transition: all ease 0.3s;
    }
  }

  &.center {
    text-align: center;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .default-inputfield {
    width: calc(50% - 20px);
    position: relative;
  }
}
</style>
